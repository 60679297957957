@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


html,
body,
:root{
  height: 100%;
  background-color: rgb(255, 255, 255);
  color-scheme: dark;
}


  @keyframes plane {
    0% {
      transform: translateX(360%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-plane {
    animation-name: plane;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes house {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(90%);
    }
  }
  
  .animate-house {
    animation-name: house;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }


  .rounded-right {
    border-radius: 0 1rem 1rem 0 !important;
  }
